<template>
  <a :href="link" target="_blank" class="programs_clickbox">
    <div class="programs_card m2 p2">
      
      <img :src="icon" alt="program_icon" class="program_icons" />
      <div class="_text-center program_title mt-1">{{ title }}</div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    link: String,
    title: String,
    icon: String,
  },
};
</script>
